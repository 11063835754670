import { FC, useState, useEffect, Fragment } from "react";
import useWindowSize from "../../hooks/useWindowSize";

interface ITabsData {
	_id: string;
	_id2: string;
	number?: string;
	name: string;
	navId: string;
	dataTarget: string;
	ariaControls: string;
	active?: string;
	data: string;
}

interface IHorizontalTabs {
	tabsData: ITabsData[];
}

const HorizontalTabs: FC<IHorizontalTabs> = ({ tabsData }) => {
	const { width } = useWindowSize();
	const [isMobile, setIsMobile] = useState("");

	useEffect(() => {
		const isWidth = () => (width < 992 ? "active" : "");
		setIsMobile(isWidth);
	}, [setIsMobile, width]);

	return (
		<div className="tabs-holder">
			{!isMobile && (
				<nav>
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						{tabsData.map((item: any) => (
							<button
								className={`nav-link ${item?.active || ""}`}
								id={`${item.navId}`}
								data-bs-toggle="tab"
								data-bs-target={`${item?.dataTarget}`}
								type="button"
								role="tab"
								aria-controls={`${item?.ariaControls}`}
								aria-selected="true"
								key={item._id}
							>
								<p className="number">{item?.number || ""}</p>

								<p className="name">{item?.name}</p>
							</button>
						))}
					</div>
				</nav>
			)}
			<div className="tab-content" id="nav-tabContent">
				{tabsData.map((item: any) => (
					// @ts-ignore
					<Fragment key={item._id2}>
						{isMobile && <h2 className="title my-4">{item.name}</h2>}
						<div
							className={`tab-pane fade show ${item?.active || isMobile || ""}`}
							id={`${item?.ariaControls}`}
							role="tabpanel"
							aria-labelledby={`${item?.navId}`}
						>
							<p
								className="tab-body"
								dangerouslySetInnerHTML={{ __html: item?.data }}
							></p>
						</div>
					</Fragment>
				))}
			</div>
		</div>
	);
};

export default HorizontalTabs;
