import { Fragment } from "react";
import type { NextPage } from "next";
import Head from "next/head";

import HomePage from "../src/@pages/HomePage/HomePage";
import Schema from "../src/components/Layout/Schema";

const Home: NextPage = () => {
	const site =
		process.env.NEXT_PUBLIC_SITE_URL || "https://staging.buylowwarehouse.com";

	const schema = {
		"@context": "https://schema.org",
		"@type": "NewsArticle",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": site,
		},
		headline: "BuyLow Warehouse Liquidation Truckloads",
		image: [],
		datePublished: new Date(),
		dateModified: new Date(),
		author: {
			"@type": "Person",
			name: "BuyLow Warehouse",
			url: site + "/about",
		},
		publisher: {
			"@type": "Organization",
			name: "BuyLow Warehouse",
			logo: {
				"@type": "ImageObject",
				url: site + "/img/logo.jpg",
			},
		},
	};

	return (
		<Fragment>
			<Head>
				<title>BuyLow Warehouse - Liquidation Marketplace</title>
				<meta
					name="description"
					content="Your Liquidation Truckload, Pallet and Boxlot specialist.  Honest, Consistent and Value-Focused."
				/>
			</Head>
			<Schema siteTitle="home" schema={schema}></Schema>
			<HomePage />
		</Fragment>
	);
};

export default Home;
