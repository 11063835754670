import Head from "next/head";
import { FC, ReactNode } from "react";

interface IHeadData {
	siteTitle: string;
	schema?: any;
	children?: string | ReactNode | JSX.Element | JSX.Element[];
}

const Schema: FC<IHeadData> = ({ siteTitle, schema = {}, children }) => {
	return (
		<Head>
			{children}
			<script
				key={`schemaJSON-${siteTitle}`}
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
			/>
		</Head>
	);
};

export default Schema;
