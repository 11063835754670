import Link from "next/link";
import React from "react";
import styles from "./heroGO2023.module.scss";
import hero from "./../../../public/strips.png";
import heroPlay from "./../../../public/play-button.png";
import Image from "next/image";
interface Hero2023Props {}

function Hero2023({}: Hero2023Props): JSX.Element {
	return (
		<section className="section-one pallets">
			<video width="auto" height="auto" autoPlay muted id="myVideo">
				<source src="/video/GO2023small2.mp4" type="video/mp4" />
			</video>
			<div className={styles.backgroundColor}></div>
			<div className={styles.background}>
				<Image
					src={hero}
					alt="hero"
					layout="fill"
					objectFit="cover"
					quality={100}
				/>
			</div>
			<div className="container">
				<div className="text-holder position-relative">
					<h1 className={styles.titleBox}>
						<span
							className={styles.titleItem}
							data-aos="fade-in"
							data-aos-duration="750"
							data-aos-delay="400"
							data-aos-once="true"
						>
							Pallet
						</span>
						<span
							className={styles.titleItem}
							data-aos="fade-in"
							data-aos-duration="750"
							data-aos-delay="1000"
							data-aos-once="true"
						>
							{" "}
							Warehouse
						</span>
						<span
							className={styles.titleItem}
							data-aos="fade-in"
							data-aos-duration="750"
							data-aos-delay="1600"
							data-aos-once="true"
						>
							{" "}
							NOW OPEN
						</span>
					</h1>

					<h4
						className={styles.textBox}
						data-aos="fade-in"
						data-aos-duration="750"
						data-aos-delay="2300"
						data-aos-once="true"
					>
						<span className={styles.text}>
							Address: 3313 1st Ave N Bay C, Birmingham, AL 35222
						</span>
						<span className={styles.text}>Phone Number: (800) 797-0385</span>
						<span className={styles.text}>Tuesday 10am - 4pm</span>
						<span className={styles.text}>Wednesday 1pm - 4pm</span>
						<span className={styles.text}>Thursday 1pm - 4pm</span>
						<span className={styles.text}>Firday 10am - 4pm</span>
						<span className={styles.text}>Saturday 8am - 2pm</span>
					</h4>
					<div className={styles.buttonBox}>
						<Link href="https://pallets.buylowwarehouse.com">
							<a className={styles.button} target="_blank">
								Online Pallet Inventory
							</a>
						</Link>

						{/* <div className={styles.buttonOne}>
							<div className={styles.buttonOnePlay}>
								<Image
									src={heroPlay}
									width="50px"
									height="50px"
									alt="hero"
									layout="fill"
									objectFit="cover"
									quality={100}
								/>
							</div>
							<span>Watch Opening Video</span>
						</div> */}
					</div>
				</div>
			</div>
		</section>
	);
}

export default Hero2023;
