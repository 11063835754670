import { makeId } from "./makeId";

export const A_STREAM_LINED_PROCESS = [
	{
		_id: `${makeId(5)}`,
		_id2: `${makeId(5)}`,
		number: "1",
		name: "Find Product",
		navId: "nav-findproduct-tab",
		dataTarget: "#nav-findproduct",
		ariaControls: "nav-findproduct",
		active: "active",
		data: `The BuyLow Warehouse team focuses on offering a diverse
    selection of truckloads, pallets, and box lots at competitive
    pricing in an easy-to-search-format so that you can always
    find what you are looking for.`,
	},
	{
		_id: `${makeId(5)}`,
		_id2: `${makeId(5)}`,
		number: "2",
		name: "Select",
		navId: "nav-select-tab",
		dataTarget: "#nav-select",
		ariaControls: "nav-select",
		data: `Selecting the product offering that you want is easy. We are
    here to help you find the right offering for your business,
    not the offering that we only want or need to sell. When you
    ask us questions, you will receive clear answers, allowing you to
    decide what is best for you.`,
	},
	{
		_id: `${makeId(5)}`,
		_id2: `${makeId(5)}`,
		number: "3",
		name: "Checkout",
		navId: "nav-checkout-tab",
		dataTarget: "#nav-checkout",
		ariaControls: "nav-checkout",
		data: `All product and freight charges will be itemized, with all
    wiring information clearly outlined on the invoice for easy
    payment by your bank.`,
	},
	{
		_id: `${makeId(5)}`,
		_id2: `${makeId(5)}`,
		number: "4",
		name: "Track",
		navId: "nav-track-tab",
		dataTarget: "#nav-track",
		ariaControls: "nav-track",
		data: `The BuyLow Warehouse team has team members that are
    dedicated to ensuring that your load is picked up and
    delivered when it is expected and that you are kept informed
    throughout the entire process.`,
	},
	{
		_id: `${makeId(5)}`,
		_id2: `${makeId(5)}`,
		number: "5",
		name: "Receive",
		navId: "nav-receive-tab",
		dataTarget: "#nav-receive",
		ariaControls: "nav-receive",
		data: `The BuyLow Warehouse team manages the entire process so that
    all you have to worry about is receiving and listing the
    merchandise for sale!`,
	},
];
